import {
  Box,
  Collapse,
  Avatar,
  HStack,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  AvatarBadge,
  Td,
  Tr,
  Tbody,
  Table,
  TableContainer,
} from "@chakra-ui/react";
//import { RiWhatsappFill } from "react-icons/ri";
import {
  MdOutlineLocalFireDepartment,
  MdInfoOutline,
  MdOutlineCalendarMonth,
} from "react-icons/md";
import React from "react";
import { UserTrainings } from "./Tabs/UserTrainings";
import { UserBonos } from "./Tabs/UserBonos";
import { UserInfo } from "./Tabs/UserInfo";
import { useState } from "react";

export function UserInfoMobile(props) {
  const { isOpen: isCardOpen, onToggle: onCardToggle } = useDisclosure();
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const options = { year: "2-digit", month: "2-digit", day: "2-digit" };

  function getDate(d) {
    let date = new Date(d);
    //console.log(date);
    return new Intl.DateTimeFormat("es-ES", options).format(date);
  }

  function phoneFormat(numero) {
    // Elimina cualquier carácter que no sea un dígito
    const numeroLimpio = numero.replace(/\D/g, "");

    // Verifica si el número tiene al menos 9 dígitos (incluyendo el prefijo +34)
    if (numeroLimpio.length < 9) {
      return "Número de teléfono no válido";
    }

    // Extrae el prefijo y el número restante
    //const prefijo = numeroLimpio.slice(0, 2);
    const resto = numeroLimpio.slice(2);

    // Aplica el formato deseado: antes`+${prefijo} ${resto.match(/.{1,3}/g).join(" ")}`;
    const formatoTelefono = `${resto.match(/.{1,3}/g).join(" ")}`;

    return formatoTelefono;
  }

  function handleClickMobile(e) {
    onCardToggle();
    setHasBeenOpened(true); // this is to avoid the first render of each user (n api calls)
  }

  return (
    <>
      <Box
        id={props.user.id}
        m="auto"
        role={"group"}
        bg="white"
        boxShadow="sm"
        w="100%"
        className="prevent-select"
        cursor={"pointer"}
        onClick={handleClickMobile}
        border="1px solid #ddd"
      >
        <HStack
          px={{ base: 6, sm: 12 }}
          py={{ base: 4, sm: 4 }}
          fontSize={{ base: "md", sm: "2xl" }}
          fontWeight={500}
        >
          <Avatar
            id={props.user.id}
            size={{ base: "sm", sm: "md" }}
            src={props.user.avatar}
            name={props.user.name + " " + props.user.surname1.split(" ")[0]}
            alt={props.user.name + " " + props.user.surname1.split(" ")[0]}
          >
            <AvatarBadge
              id={props.user.id}
              boxSize="1.25em"
              bg={props.user.enabled ? "green.500" : "red.500"}
            />
          </Avatar>
          <div className="text-overflow-mobile">
            {props.user.name}
            <span className="blue-text">
              {" " + props.user.surname1 + " " + props.user.surname2}
            </span>
          </div>
        </HStack>
      </Box>
      <Collapse in={isCardOpen}>
        {hasBeenOpened && (
          <>
            <Box w="100%" m="auto" background="#EDF2F7">
              {/*<Button
            title="Editar usuario"
            variant="ghost"
            size={{ base: "sm", sm: "sm", md: "sm" }}
            fontWeight={400}
            background-color="#EDF2F7"
          >
            Editar usuario&nbsp;
            <MdEditNote size="1.25em" />
  </Button>*/}
            </Box>
            <TableContainer
              w={"100%"}
              maxW="1300px"
              m="auto"
              py={{ md: 2, lg: 4, xl: 0 }}
            >
              <Table variant="simple" size="sm" my={1}>
                <Tbody>
                  <Tr>
                    <Td pr={0}>Fecha de nacimiento</Td>
                    <Td isNumeric pl={0}>
                      {getDate(props.user.birthday)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td pr={0}>Correo electrónico</Td>
                    <Td isNumeric pl={0}>
                      {props.user.email}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td pr={0}>Teléfono</Td>
                    <Td isNumeric pl={0}>
                      <a
                        href={"https://wa.me/" + props.user.phone}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {phoneFormat(props.user.phone)}
                      </a>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td pr={0}>Sexo</Td>
                    <Td isNumeric pl={0}>
                      {props.user.gender === "MALE" ? "Hombre" : "Mujer"}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            <Tabs isFitted isLazy lazyBehavior="keepMounted">
              <TabList m="auto">
                <Tab>
                  <MdOutlineLocalFireDepartment />
                  &nbsp;Bonos
                </Tab>
                <Tab>
                  <MdOutlineCalendarMonth />
                  &nbsp;Reservas
                </Tab>
                <Tab>
                  <MdInfoOutline />
                  &nbsp;Info
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel className="p0-tab-panel pt0-tab-panel pb0-tab-panel">
                  <UserBonos userId={props.userId} bonoEdit={true} />
                </TabPanel>
                <TabPanel className="p0-tab-panel pt0-tab-panel pb0-tab-panel">
                  <UserTrainings userId={props.userId} />
                </TabPanel>
                <TabPanel className="p0-tab-panel" pb={"6px !important"}>
                  <UserInfo user={props.user} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </Collapse>
    </>
  );
}
