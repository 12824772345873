import * as React from "react";
import {
  ChakraProvider,
  Box,
  Heading,
  Avatar,
  Switch,
  Stack,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Skeleton,
  SkeletonCircle,
  useToast,
  Input,
  FormControl,
  FormLabel,
  Text,
  HStack,
  ModalBody,
  ModalFooter,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Button,
  Spacer,
} from "@chakra-ui/react";
import { useAuth } from "../auth/useAuth";
//import { IoCameraOutline } from "react-icons/io5";
import { UserBonos } from "../components/Activity/UserBonos";
import { UserBookings } from "../components/Activity/UserBookings";
import { MdOutlineBook, MdOutlineLocalFireDepartment } from "react-icons/md";
import axios from "axios";
import { useEffect, useState } from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Helmet } from "react-helmet-async";

export function Profile() {
  const auth = useAuth();
  const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
  const [user, setUser] = useState(null);
  const [isConfigDisabled, setIsConfigDisabled] = useState(false);
  const [avatarModal, setAvatarModal] = useState(false);
  const toast = useToast();

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/api/user");
      setUser(data);
    })();
  }, []);

  function getDate(d) {
    let date = new Date(d);
    return new Intl.DateTimeFormat("es-ES", options).format(date);
  }

  function getGender(g) {
    return g === "MALE" ? "Hombre" : "Mujer";
  }

  function phoneFormat(numero) {
    // Elimina cualquier carácter que no sea un dígito
    const numeroLimpio = numero.replace(/\D/g, "");

    // Verifica si el número tiene al menos 9 dígitos (incluyendo el prefijo +34)
    if (numeroLimpio.length < 9) {
      return "Número de teléfono no válido";
    }

    // Extrae el prefijo y el número restante
    const prefijo = numeroLimpio.slice(0, 2);
    const resto = numeroLimpio.slice(2);

    // Aplica el formato deseado
    const formatoTelefono = `+${prefijo} ${resto.match(/.{1,3}/g).join(" ")}`;

    return formatoTelefono;
  }

  async function handleEmailNotifChange() {
    setIsConfigDisabled(true);
    await axios
      .put("/api/user/config", {
        emailNotifEnabled: !user.emailNotifEnabled,
        showAttendance: user.showAttendance,
      })
      .then(() => {
        toast({
          title: "Configuración actualizada",
          description: "Envío de notificaciones por correo actualizado",
          position: "bottom-right",
          duration: 3000,
          isClosable: true,
        });
        setUser({ ...user, emailNotifEnabled: !user.emailNotifEnabled });
      })
      .catch((error) => {
        toast({
          title: "¡Error inesperado!",
          description: error.response.data.message,
          status: "error",
          position: "bottom-right",
          duration: 3000,
          isClosable: true,
        });
      });

    setIsConfigDisabled(false);
  }

  async function handleShowAttendanceEnabled() {
    setIsConfigDisabled(true);

    await axios
      .put("/api/user/config", {
        emailNotifEnabled: user.emailNotifEnabled,
        showAttendanceEnabled: !user.showAttendanceEnabled,
      })
      .then(() => {
        toast({
          title: "Configuración actualizada",
          description: "Privacidad de asistencia actualizada",
          position: "bottom-right",
          duration: 3000,
          isClosable: true,
        });
        setUser({
          ...user,
          showAttendanceEnabled: !user.showAttendanceEnabled,
        });
      })
      .catch((error) => {
        toast({
          title: "¡Error inesperado!",
          description: error.response.data.message,
          status: "error",
          position: "bottom-right",
          duration: 3000,
          isClosable: true,
        });
      });

    setIsConfigDisabled(false);
  }

  function uploadAvatar(value) {
    if (value === null) {
      setAvatarModal(false);
      return;
    }

    if (value) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("file", file);

      axios
        .put("/api/user/avatar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          toast({
            title: "¡Avatar actualizado!",
            description: "",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });

          setAvatarModal(false);
          setIsLoading(false);
          setFile(null);
          auth.updateUserAvatar(response.data);
        })
        .catch((error) => console.error("Error al actualizar avatar", error));
    } else {
      // TODO: do this
      console.log("eliminar");
    }
  }

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 1 * 1024 * 1024; // 1MB en bytes

    if (file.size > maxSize) {
      setError("El archivo seleccionado pesa más de 1 MB");
      return;
    } else {
      setError(null);
    }

    if (file && file.size < maxSize) {
      setFile(file);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (file) {
      uploadAvatar(file);
    }
  };

  const deleteAvatar = () => {
    setIsDeleteLoading(true);
    axios
      .delete("/api/user/avatar")
      .then(() => {
        toast({
          title: "Avatar elminado con éxito",
          description: "",
          position: "bottom-right",
          duration: 3000,
          isClosable: true,
        });

        setAvatarModal(false);
        setIsDeleteLoading(false);
        setFile(null);
        auth.updateUserAvatar("");
      })
      .catch((error) => console.error("Error al actualizar avatar", error));
  };

  return (
    <>
      <Helmet>
        <title>Perfil - CUC Fitness Studio</title>
        <meta
          name="description"
          content="Área personal de CUC Fitness Studio."
        />
      </Helmet>
      <ChakraProvider>
        <ModalAvatar
          isOpen={avatarModal}
          onClose={avatarModal}
          returnFocusOnClose={false}
        />
        <Box
          pt={"100px"}
          textAlign="justify"
          margin={"auto"}
          width={{ base: "100%", sm: "100%", md: "80%" }}
          maxW="1300px"
        >
          <Stack
            className="prevent-select"
            justifyContent={{ base: "center", sm: "center", md: "flex-end" }}
            display={{ base: "flex", sm: "flex", md: "none", lg: "none" }}
          >
            {!user ? (
              <Stack spacing={6} pt={5}>
                <Skeleton width={"250px"} height={"45px"} m="auto" />
                <SkeletonCircle
                  w={{ base: "130px", sm: "150px", md: "260px" }}
                  h={{ base: "130px", sm: "150px", md: "260px" }}
                  m="auto"
                />
              </Stack>
            ) : (
              <>
                <Heading
                  fontFamily={"Oswald"}
                  fontWeight={400}
                  fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
                  lineHeight={"110%"}
                  color={"gray.800"}
                  pt={4}
                  pb={6}
                  textAlign={"center"}
                >
                  ¡Hola, {user && user.name}!
                </Heading>
                <Avatar
                  size="xl"
                  w={{ base: "130px", sm: "150px", md: "260px" }}
                  h={{ base: "130px", sm: "150px", md: "260px" }}
                  name={`${auth.user.name} ${auth.user.surname1}`.trim()}
                  src={auth.user.avatar}
                  border="2px solid gray"
                  m="auto"
                  _hover={{
                    cursor: "pointer",
                    blur: "20px !important",
                  }}
                  onClick={() => setAvatarModal(true)}
                />
              </>
            )}
          </Stack>

          <TableContainer w={"100%"} maxW="1300px" m="auto" pb={8}>
            <Table variant="simple" size="md">
              <Tbody>
                <Tr display={{ base: "none", sm: "none", md: "table-row" }}>
                  <Td>
                    <Skeleton
                      isLoaded={user}
                      width={{ md: "300px", xl: "500px" }}
                    >
                      <Heading
                        fontFamily={"Oswald"}
                        fontWeight={400}
                        fontSize={{
                          base: "40px",
                          md: "45px",
                          lg: "50px",
                          xl: "55px",
                        }}
                        lineHeight={"110%"}
                        color={"gray.800"}
                      >
                        ¡Hola, {user && user.name}!
                      </Heading>
                    </Skeleton>
                  </Td>
                  <Td isNumeric>
                    <SkeletonCircle size={24} isLoaded={user} m="auto" mr={0}>
                      <Avatar
                        size={{ base: "md", sm: "md", md: "xl" }}
                        name={`${auth.user.name} ${auth.user.surname1}`.trim()}
                        right={0}
                        src={auth.user.avatar}
                        border="2px solid gray"
                        _hover={{
                          cursor: "pointer",
                          blur: "20px",
                        }}
                        onClick={() => setAvatarModal(true)}
                      />
                    </SkeletonCircle>
                  </Td>
                </Tr>

                <Tr>
                  <Td pr={0}>Nombre</Td>
                  <Td pl={0} isNumeric>
                    <Skeleton isLoaded={!!user}>
                      {user ? user.name : "Empty"}
                    </Skeleton>
                  </Td>
                </Tr>

                <Tr>
                  <Td pr={0}>Apellidos</Td>
                  <Td pl={0} isNumeric>
                    <Skeleton isLoaded={!!user}>
                      {user ? user.surname1 + " " + user.surname2 : "Empty"}
                    </Skeleton>
                  </Td>
                </Tr>
                <Tr>
                  <Td pr={0}>F. nacimiento</Td>
                  <Td pl={0} isNumeric>
                    <Skeleton isLoaded={!!user}>
                      {user ? getDate(user.birthday) : "Empty"}
                    </Skeleton>
                  </Td>
                </Tr>
                <Tr>
                  <Td pr={0}>Teléfono</Td>
                  <Td pl={0} isNumeric>
                    <Skeleton isLoaded={!!user}>
                      {user ? phoneFormat(user.phone) : "Empty"}
                    </Skeleton>
                  </Td>
                </Tr>
                <Tr>
                  <Td pr={0}>Sexo</Td>
                  <Td pl={0} isNumeric>
                    <Skeleton isLoaded={!!user}>
                      {user ? getGender(user.gender) : "Empty"}
                    </Skeleton>
                  </Td>
                </Tr>
                <Tr>
                  <Td pr={0}>Notif. por correo</Td>
                  <Td pl={0}>
                    <Switch
                      size="md"
                      isChecked={user ? user.emailNotifEnabled : false}
                      display="flex"
                      justifyContent="right"
                      onChange={handleEmailNotifChange}
                      isDisabled={isConfigDisabled}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td pr={0}>Mostrar asistencia</Td>
                  <Td pl={0}>
                    <Switch
                      size="md"
                      isChecked={user ? user.showAttendanceEnabled : false}
                      display="flex"
                      justifyContent="right"
                      onChange={handleShowAttendanceEnabled}
                      isDisabled={isConfigDisabled}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          width={{ base: "100%", sm: "100%", md: "80%" }}
          maxW="1300px"
          m="auto"
        >
          <Heading
            fontFamily={"Oswald"}
            fontWeight={400}
            fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
            lineHeight={"110%"}
            color={"gray.800"}
            textAlign={"center"}
            px={5}
            pb={6}
          >
            Tu actividad
          </Heading>
        </Box>
        <Box pb={5}>
          <Tabs isFitted isLazy lazyBehavior="keepMounted">
            <TabList
              width={{ base: "100%", sm: "100%", md: "80%" }}
              maxW="1300px"
              m="auto"
            >
              <Tab>
                <MdOutlineLocalFireDepartment />
                &nbsp;Bonos
              </Tab>
              <Tab>
                <MdOutlineBook />
                &nbsp;Reservas
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel className="p0-tab-panel">
                <UserBonos bonoEdit={false}/>
              </TabPanel>
              <TabPanel className="p0-tab-panel">
                <UserBookings />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </ChakraProvider>
    </>
  );

  function ModalAvatar({ isOpen }) {
    return (
      <>
        <Modal
          onClose={() => setAvatarModal(false)}
          isOpen={isOpen}
          isCentered
          size="sm"
          borderRadius={"0px"}
          closeOnOverlayClick={!(isLoading || isDeleteLoading)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign="left" mt={2}>
              <Text>Foto de perfil</Text>
            </ModalHeader>
            <form onSubmit={handleSubmit} m="aut<o">
              <ModalBody>
                <Stack spacing={4}>
                  <HStack spacing={5} justifyContent="center">
                    <Avatar
                      size="xl"
                      border="2px solid var(--chakra-colors-gray-300)"
                      name={`${auth.user.name} ${auth.user.surname1}`.trim()}
                      src={auth.user.avatar}
                    />
                    <IoIosArrowRoundForward size="1.5em" color="gray" />
                    <Avatar
                      size="xl"
                      border="2px solid var(--chakra-colors-gray-300)"
                      src={file && URL.createObjectURL(file)}
                      alt="Preview"
                    />
                  </HStack>

                  <Box w="100%">
                    <FormControl>
                      <Stack
                        id="dropContainer"
                        onDrop={(evt) => {
                          setFile(evt.dataTransfer.files[0]);
                          evt.preventDefault();
                        }}
                        onDragOver={(e) => e.preventDefault()}
                        justifyContent={"center"}
                        w="auto"
                        _hover={{
                          bg: "gray.200;",
                        }}
                      >
                        <FormLabel
                          html="files"
                          textAlign="center"
                          cursor="pointer"
                          py={8}
                          px={1}
                          fontWeight="300"
                          border="1px solid var(--chakra-colors-gray-100)"
                          m={0}
                        >
                          {file
                            ? file.name
                            : "Arrastra o selecciona una imagen"}

                          {error ? (
                            <Text fontSize="sm" mt={1} color="red">
                              {error}
                            </Text>
                          ) : (
                            <Text fontSize="sm" mt={1}>
                              PNG, JPG o JPEG de hasta 1 MB
                            </Text>
                          )}
                        </FormLabel>
                      </Stack>
                      <Input
                        type="file"
                        onChange={handleFileChange}
                        accept=".jpg,.jpeg,.png"
                        display="none"
                      />
                    </FormControl>
                  </Box>
                </Stack>
              </ModalBody>

              <ModalFooter pt={2}>
                <Button
                  onClick={() => {
                    setAvatarModal(false);
                    setFile(null);
                    setError(null);
                  }}
                  borderRadius={0}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  mr={2}
                  isDisabled={isLoading || isDeleteLoading}
                >
                  Cancelar
                </Button>
                <Spacer />
                <HStack spacing={2}>
                  <Button
                    onClick={() => deleteAvatar()}
                    colorScheme="red"
                    borderRadius={0}
                    isDisabled={!auth.user.avatar || isLoading}
                    isLoading={isDeleteLoading}
                  >
                    Eliminar
                  </Button>
                  s{" "}
                  <Button
                    type="submit"
                    colorScheme="blue"
                    borderRadius={0}
                    variant="outline"
                    isDisabled={!file || isDeleteLoading}
                    isLoading={isLoading}
                  >
                    Publicar
                  </Button>
                </HStack>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  }
}

export default Profile;
