import {
  Box,
  HStack,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Avatar,
  AvatarBadge,
  Spinner,
  Stack,
  TabPanel,
  TabPanels,
  TabList,
  Tab,
  Tabs,
  //Badge,
  TableContainer,
  Tr,
  Td,
  Table,
  Tbody,
  Heading,
  Show,
} from "@chakra-ui/react";
import {
  MdOutlineSearch,
  MdClose,
  MdOutlineLocalFireDepartment,
  MdInfoOutline,
  MdOutlineCalendarMonth,
} from "react-icons/md";
//import { RiWhatsappFill } from "react-icons/ri";
//import { HiOutlineRefresh } from "react-icons/hi";
import { UserInfoMobile } from "../AdminUsers/UserInfoMobile";
import { UserBonos } from "./Tabs/UserBonos";
import { UserTrainings } from "./Tabs/UserTrainings";
import { UserInfo } from "./Tabs/UserInfo";
import React from "react";
import "./style.css";
import axios from "axios";
import { useEffect, useState } from "react";
//import EllipsisTextWithHover from "./EllipsisTextWithHover";

export function AdminUsers() {
  const [userData, setUserData] = useState(null);
  const [users, setUsers] = useState(null);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/api/admin/users");
      setSearch(data);
      setUsers(data);
    })();
  }, []);

  function handleClick(e) {
    const element = e.target.closest(".user-item");

    // Adds selected class to the clicked element (background color)
    if (document.querySelector(".selected")) {
      document.querySelector(".selected").classList.remove("selected");
    }

    element.firstChild.classList.add("selected");
    const user = users.filter((user) => user.id === parseInt(element.id))[0];
    setUserData(user);
  }

  function handleType(e) {
    const value = e.target.value;
    const filteredUsers = users.filter(
      (user) =>
        user.name.toLowerCase().includes(value.toLowerCase()) ||
        user.surname1.toLowerCase().includes(value.toLowerCase()) ||
        user.surname2.toLowerCase().includes(value.toLowerCase())
    );

    setSearch(filteredUsers);
  }

  function deleteInputValue() {
    const inputElement = document.getElementById("search-input-desktop");
    inputElement.value = "";
    setSearch(users);
  }
  function deleteInputValueMobile() {
    const inputElement = document.getElementById("search-input-mobile");
    inputElement.value = "";
    setSearch(users);
  }

  function phoneFormat(numero) {
    // Elimina cualquier carácter que no sea un dígito
    const numeroLimpio = numero.replace(/\D/g, "");

    // Verifica si el número tiene al menos 9 dígitos (incluyendo el prefijo +34)
    if (numeroLimpio.length < 9) {
      return "Número de teléfono no válido";
    }

    // Extrae el prefijo y el número restante
    //const prefijo = numeroLimpio.slice(0, 2);
    const resto = numeroLimpio.slice(2);

    // Aplica el formato deseado: antes `+${prefijo} ${resto.match(/.{1,3}/g).join(" ")}`;

    const formatoTelefono = `${resto.match(/.{1,3}/g).join(" ")}`;

    return formatoTelefono;
  }

  return (
    <>
      <Box
        width="80%"
        margin="auto"
        maxW="1300px"
        mt={{ base: 1, sm: 1, md: 0 }}
        pb={{ base: 6, sm: 0 }}
        display={{ base: "none", sm: "none", md: "block" }}
      >
        <div className="container">
          <div className="sidebar">
            <div className="search-bar">
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <MdOutlineSearch />
                </InputLeftElement>
                <InputRightElement>
                  <MdClose cursor="pointer" onClick={deleteInputValue} />
                </InputRightElement>
                <Input
                  id="search-input-desktop"
                  type="text"
                  placeholder="Buscar..."
                  onChange={handleType}
                />
              </InputGroup>
            </div>
            <div className="user-list">
              <ul>
                {search ? (
                  search.map((user) => (
                    <li
                      id={user.id}
                      key={user.id}
                      className="user-item"
                      width="50%"
                      title={
                        user.name + " " + user.surname1 + " " + user.surname2
                      }
                    >
                      <HStack
                        key={user.id}
                        id={user.id}
                        m="auto"
                        role={"group"}
                        p={{ md: 2, lg: 4 }}
                        bg="white"
                        boxShadow="sm"
                        border="1px solid #E2E8F0"
                        w="100%"
                        onClick={handleClick}
                        className="prevent-select"
                        cursor={"pointer"}
                        spacing={0.5}
                        _hover={{
                          border: "1px solid #CBD5E0",
                        }}
                      >
                        <Avatar
                          id={user.id}
                          size={{ md: "2xs", lg: "xs", xl: "sm" }}
                          src={user.avatar}
                          name={`${user.name} ${user.surname1}`.trim()}
                          alt={user.name + " " + user.surname1}
                        >
                          <AvatarBadge
                            boxSize="1.25em"
                            bg={user.enabled ? "green.500" : "red.500"}
                          />
                        </Avatar>

                        <Box
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          ml={3}
                        >
                          {/*<EllipsisTextWithHover>
                          {user.name + " "}
                          <span className="blue-text">
                            {user.surname1 + " " + user.surname2}
                          </span>
                    </EllipsisTextWithHover>*/}
                          <div className="text-overflow">
                            {user.name + " "}
                            <span className="blue-text">
                              {user.surname1 + " " + user.surname2}
                            </span>
                          </div>
                        </Box>
                      </HStack>
                    </li>
                  ))
                ) : (
                  <Spinner size="xl" mt={5} />
                )}
              </ul>
            </div>
            <div className="total-bar">
              <Text fontSize="xs" py={0.5}>
                Total Usuarios: {search && search.length}
              </Text>
            </div>
          </div>

          {userData && (
            <div className="content">
              <Box className="content-item" m="auto" h="40%">
                <TableContainer
                  w={"100%"}
                  maxW="1300px"
                  m="auto"
                  py={{ md: 2, lg: 4, xl: 4 }}
                >
                  <Table variant="simple" size="sm">
                    <Tbody>
                      <Tr
                        display={{ base: "none", sm: "none", md: "table-row" }}
                      >
                        <Td maxW="150px" pr={0}>
                          <Stack spacing={{ md: 1.5, lg: 2, xl: 2.5 }}>
                            <Heading
                              fontWeight={300}
                              letterSpacing={-0.5}
                              fontSize={{
                                base: "20px",
                                md: "22px",
                                lg: "28px",
                                xl: "40px",
                              }}
                              lineHeight={"110%"}
                              color={"gray.800"}
                            >
                              {userData.name +
                                " " +
                                userData.surname1 +
                                " " +
                                userData.surname2}
                            </Heading>
                            <HStack>
                              <Text
                                fontSize={{
                                  base: "20px",
                                  md: "12px",
                                  lg: "18px",
                                  xl: "22px",
                                }}
                              >
                                {getDate(userData.birthday)}
                              </Text>
                              {/*<Badge
                                variant="solid"
                                colorScheme="green"
                                size="1.5em"
                              >
                                ACTIVO
                              </Badge>*/}
                            </HStack>
                          </Stack>
                        </Td>
                        <Td isNumeric pl={0}>
                          <Avatar
                            key={userData.id}
                            className="prevent-select"
                            name={`${userData.name} ${userData.surname1}`.trim()}
                            src={userData.avatar}
                            _hover={{
                              cursor: "pointer",
                            }}
                            size={{
                              md: "md",
                              lg: "lg",
                              xl: "xl",
                            }}
                            onClick={() => console.log("Cambiar foto")}
                          />
                        </Td>
                      </Tr>

                      <Tr>
                        <Td pr={0}>Correo electrónico</Td>
                        <Td isNumeric pl={0}>
                          {userData.email}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td pr={0}>Teléfono</Td>
                        <Td isNumeric pl={0}>
                          <a
                            href={"https://wa.me/" + userData.phone}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {phoneFormat(userData.phone)}
                          </a>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td pr={0}>Sexo</Td>
                        <Td isNumeric pl={0}>
                          {userData.gender === "MALE" ? "Hombre" : "Mujer"}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                className="content-item"
                m="auto"
                h="60%"
                overflowY="hidden"
                overflowX="hidden"
              >
                <Tabs isFitted isLazy lazyBehavior="keepMounted" h="100%">
                  <TabList m="auto">
                    <Tab>
                      <MdOutlineLocalFireDepartment />

                      <Text display={{ base: "none", sm: "none", md: "flex" }}>
                        &nbsp;Bonos
                      </Text>
                    </Tab>
                    <Tab>
                      <MdOutlineCalendarMonth />
                      <Text display={{ base: "none", sm: "none", md: "flex" }}>
                        &nbsp;Reservas
                      </Text>
                    </Tab>
                    <Tab>
                      <MdInfoOutline />
                      <Text display={{ base: "none", sm: "none", md: "flex" }}>
                        &nbsp;Info
                      </Text>
                    </Tab>
                  </TabList>

                  <TabPanels h="100%">
                    <TabPanel
                      className="p0-tab-panel pt0-tab-panel"
                      maxH="92%"
                      overflowY="auto"
                    >
                      <UserBonos userId={userData.id} bonoEdit={true}/>
                    </TabPanel>
                    <TabPanel
                      className="p0-tab-panel pt0-tab-panel"
                      maxH="92%"
                      overflowY="auto"
                    >
                      <UserTrainings userId={userData.id} />
                    </TabPanel>
                    <TabPanel
                      className="p0-tab-panel"
                      pt={1}
                      maxH="92%"
                      overflowY="auto"
                    >
                      <UserInfo user={userData} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </div>
          )}

          {!userData && (
            <div className="content">
              <Box className="content-item" m="auto" h="40%">
                <Stack
                  pt={{ md: "150px", lg: "180px", xl: "200px" }}
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  height="auto"
                  color="gray.600"
                >
                  <MdInfoOutline size="2em" />
                  <Text mt={1} fontSize={{ md: "lg", lg: "2xl" }}>
                    Selecciona un usuario para continuar
                  </Text>
                </Stack>
              </Box>
            </div>
          )}
        </div>
      </Box>

      <Box
        //width="100%"
        mx={2}
        mt={-2}
        pb={0}
        display={{ base: "block", sm: "block", md: "none" }}
      >
        <InputGroup mb={"8px"}>
          <InputLeftElement pointerEvents="none">
            <MdOutlineSearch />
          </InputLeftElement>
          <InputRightElement>
            <MdClose cursor="pointer" onClick={deleteInputValueMobile} />
          </InputRightElement>
          <Input
            id="search-input-mobile"
            borderBlockEndStyle={"4px yellow solid"}
            type="text"
            placeholder="Buscar..."
            onChange={handleType}
          />
        </InputGroup>
        <ul>
          {search ? (
            search.map((user) => (
              <li key={user.id} className="user-item-mobile" width="50%">
                <UserInfoMobile
                  user={user}
                  userId={user.id}
                  userAvatar={user.avatar}
                  userName={user.name}
                  userSurname={user.surname1 + " " + user.surname2}
                  userEmail={user.email}
                  userPhoneNumber={user.phone}
                  userBirthday={getDate(user.birthday)}
                  userCreated={getFullTimestamp(user.created)}
                  userModified={getFullTimestamp(user.modified)}
                  userRoles={user.roles}
                  userEnabled={user.enabled}
                />
              </li>
            ))
          ) : (
            <Spinner size="xl" my={5} />
          )}
        </ul>
      </Box>
      {users && (
        <Show below="md">
          <div className="total-bar total-bar-mb ">
            <Text fontSize="xs" py={3}>
              Total Usuarios: {search && search.length}
            </Text>
          </div>
        </Show>
      )}
    </>
  );
}

const options = { year: "2-digit", month: "2-digit", day: "2-digit" };

function getDate(d) {
  let date = new Date(d);
  //console.log(date);
  return new Intl.DateTimeFormat("es-ES", options).format(date);
}

function getFullTimestamp(d) {
  return (
    new Date(d)
      .toLocaleDateString("es-ES", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("/") +
    ", " +
    new Date(d).toLocaleTimeString("es-ES", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  );
}
